import React, { FC } from 'react';
import DropdownMenu from './DropdownMenu';
import styles from './SettingsIcon.module.css';

interface SettingsIconProps {
  isSettingsDropDownVisible: Boolean;
  onSignOut: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onEditProfile: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onSettingsIconClicked: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onDeleteAccount: (event: React.MouseEvent<HTMLButtonElement>) => void; // NEW
}

const SettingsIcon: FC<SettingsIconProps> = ({ 
  isSettingsDropDownVisible, 
  onSignOut, 
  onEditProfile, 
  onSettingsIconClicked,
  onDeleteAccount // NEW
}) => {
  return (
    <div className={styles.settingsContainer}>
      <i className="fas fa-cog" onClick={onSettingsIconClicked}></i>
      {isSettingsDropDownVisible && (
        <DropdownMenu 
          onSignOut={onSignOut} 
          onEditProfile={onEditProfile} 
          onDeleteAccount={onDeleteAccount} // NEW
        />
      )}
    </div>
  );
};

export default SettingsIcon;