import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, User } from "firebase/auth";
import { useMediaQuery } from 'react-responsive';
import Carousel from '../../Carousel/Carousel';
import styles from './Home.module.css';

import combinedExploreImg from '../../images/combined_explore.jpg';
import bbqImg from '../../images/bbq.webp';
import mtbImg from '../../images/mtn-bike.webp';
import surfImg from '../../images/surf.webp';
import MobileHeader from "./MobileHeader";
import { getUserProfile } from "../../firebaseFunctions";
import { UserProfile } from "../../entities/UserProfile";
import { deleteUserAccount } from "../../services/accountService";

export const Home = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const navigate = useNavigate();
  const auth = getAuth();

  const [userprofile, setUserProfile] = useState<UserProfile | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  const images = [bbqImg, mtbImg, surfImg];

  const [isSettingsDropdownVisible, setIsSettingsDropdownVisible] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const fetchUserProfile = async () => {
          try {
            const userProfile = await getUserProfile(user.uid);
            setUserProfile(userProfile);
            // Do something with userProfile here, if needed
          } catch (error) {
            console.error("Failed to fetch user profile:", error);
            // Handle any errors, if necessary
          }
        };

        fetchUserProfile();
      }
      setIsAuthenticated(!!user); // Simplified authentication check
    });

    return unsubscribe; // Cleanup on component unmount
  }, [auth]);

  useEffect(() => {

    const handleKeyDown = (event: any) => {
      if (event.key === 'Escape') {
        console.log('Escape key was pressed');
        setIsSettingsDropdownVisible(prevIsDropdownVisible => false);
      }
    };

    // Add event listener for keydown
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Empty dependency array means this effect runs only once on mount

  const signOut = (event: any) => {
    event.stopPropagation();
    auth.signOut();
    window.localStorage.setItem('emailForSignIn', "");
    navigate("/signin");
  };

  const onEditProfileClicked = (event: any) => {
    event.stopPropagation();
    navigate("/editProfile");
  };

  const signInOrRegister = (event: any) => {
    event.stopPropagation();
    navigate("/signin");
  };

  const navigateToDeleteAccount = (event: any) => {
    event.stopPropagation();
    navigate("/deleteAccount");
  };

  const toggleSettingsDropdown = (event: any) => {
    event.stopPropagation();
    console.log(`Toggle dropdown clicked. Visible: ${isSettingsDropdownVisible}`);
    setIsSettingsDropdownVisible(prevIsDropdownVisible => !prevIsDropdownVisible);
  }

  if (isAuthenticated === null) {
    // Show loading spinner when the authentication state is unknown
    return <div className="centered-screen"><div className="loader"></div></div>;
  }

  return (
    <div onClick={() => setIsSettingsDropdownVisible(false)}>
      <MobileHeader isSettingsDropDownVisible={isSettingsDropdownVisible} isAuthenticated={isAuthenticated} user={userprofile} signInOrRegisterClicked={signInOrRegister} signOutClicked={signOut} onEditProfileClicked={onEditProfileClicked} onSettingsIconClicked={toggleSettingsDropdown} onDeleteAccountClicked={navigateToDeleteAccount} // NEW
      />
      <div className={styles.storeButtons}>
        <a href="https://play.google.com/store/apps/details?id=au.com.discoverdo.app" target="_blank" rel="noopener noreferrer">
          <img src="googleplay.png" alt="Download on Google Play" className={styles.storeButton} />
        </a>
        <a href="https://apps.apple.com/au/app/discoverdo/id6738029153" target="_blank" rel="noopener noreferrer">
          <img src="appstore.png" alt="Download on the App Store" className={styles.storeButton} />
        </a>
      </div>

      <div className={styles.overlayContainer}>
        {isMobile ? (
          <>
            <div className={styles.mobileImage}>
              <Carousel images={images} />
            </div>
            {/* Text below the Carousel for mobile */}
            <div className={styles.mobileText}>
              <h6>Discover New Activities and Create Memorable Connections with DiscoverDo!</h6>
            </div>
          </>
        ) : (
          <>
            <img src={combinedExploreImg} className={styles.desktopImage} alt="DiscoverDo" />
            {/* Overlay Text for desktop */}
            <div className={styles.overlayText}>
              <h6>Discover New Activities and Create Memorable Connections with DiscoverDo!</h6>
            </div>
          </>
        )}
      </div>

      {userprofile ? (
        <div>
          <div className="container mt-3">
            <p className="text-center">Welcome back to DiscoverDo! Whether you're looking for an adventure or a quiet escape, we're here to inspire your next activity.</p>
            <div className="text-center mt-3">
              {userprofile.isSubscribedToEmails ? (
                <b>✅ Great! You're receiving our weekly hand-picked activities by email. ✅</b>
              ) : (
                <div>
                  <b>🔔 You're not subscribed to our weekly emails. Don't miss out on exclusive activities tailored just for you. 🔔</b>
                  <div className="text-center mt-4">
                    <Link to="/editProfile" className="btn btn-primary">Opt In for Emails</Link>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="container mt-4">
            <h3 className="text-center">What DiscoverDo stands for</h3>
            <p>At the heart of DiscoverDo is a commitment to rekindling the joy of discovery. In a world where routines dominate, our mission is to bring spontaneity and surprise back into your life. Here’s how we do it:</p>
            <ul>
              <li><strong>Curated Activities:</strong> Our selections are carefully chosen to suit a wide range of interests.</li>
              <li><strong>AI-Powered Suggestions:</strong> Leveraging AI, we personalize recommendations to fit your preferences and current conditions like weather.</li>
              <li><strong>Minimal Screen Time, Maximum Engagement:</strong> With our weekly suggestions sent directly to your inbox, we reduce the time you spend searching so you can spend more time doing.</li>
            </ul>
          </div>
        </div>
      ) : (
        <div>
          <div className="container mt-3">
            <p className="text-center">Ever pondered over a weekend: <b className="blueText">"What should I do today?"</b></p>
            <p className="text-center">Looking for a <b className="blueText">nudge</b> to step out of your daily routine?</p>
          </div>

          <div className="container-fluid mt-5 p-3">
            <div className={`${styles.roundedBorder} p-3`}>
              <h5 className="text-center">DiscoverDo Offers a Solution</h5>
              <p>From crafting your very own pottery masterpiece 🏺, taking surfing lessons 🏄‍♂️, going to a comedy show 😂, to simmering a delicious French stew in your kitchen 🍲 - the possibilities are endless.</p>
              <ul style={{ marginTop: '16px' }}>
                <li><p>Ideas come directly to your inbox - say goodbye to endless scrolling.</p></li>
                <li><p>Enjoy smart recommendations that cater to your unique tastes and adapt to current conditions.</p></li>
              </ul>
              <div className="text-center mt-4">
                <button className="custom-button" onClick={signInOrRegister}>Sign Up & Start Discovering</button>
              </div>
            </div>
          </div>

        </div>
      )}


      <div className="text-center mt-5">
        <p>Currently optimised for Sydney and Melbourne.</p>
      </div>
    </div>
  );
};
