import { useEffect, useState } from "react";
import React from 'react';
import { Navigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { checkUserInterestExists } from "../firebaseFunctions";
import { CompleteProfile } from "./CompleteProfile/CompleteProfile";

export const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const auth = getAuth();

    // States
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
    const [hasInterests, setHasInterests] = useState<boolean | null>(null);
    const [wasPreviouslyAuthenticated, setWasPreviouslyAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                console.log("Checking user interests state");
                try {
                    const doesExist = await checkUserInterestExists(user.uid);
                    console.log(`User exists: ${doesExist}`);
                    setHasInterests(doesExist);
                } catch (error) {
                    console.error('Error checking user interest: ', error);
                }
                setIsAuthenticated(true);
                setWasPreviouslyAuthenticated(true);
            } else {
                setIsAuthenticated(false);
                setHasInterests(false);
            }
        });

        return () => {
            unsubscribe();
        };
    }, [auth]);

    const handleProfileCompletion = () => {
        console.log('Profile update completed');
        setHasInterests(true);
    };

    // Render logic
    if (isAuthenticated === null || (isAuthenticated === true && hasInterests === null)) {
        return <div className="centered-screen"><div className="loader"></div></div>; // Loading state
    } else if (isAuthenticated && hasInterests === true) {
        return <>{children}</>; // Authenticated and has interests
    } else if (isAuthenticated && hasInterests === false) {
        return <CompleteProfile onCompletion={handleProfileCompletion} />;
    } else if (!isAuthenticated && !wasPreviouslyAuthenticated) {
        return <Navigate replace to="/signin" state={{ from: window.location.href }} />; // First sign-in attempt
    } else {
        return <>{children}</>; // Signed out but previously authenticated
    }
};