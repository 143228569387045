import axios from "axios";
import { getAuth } from "firebase/auth";

export const deleteUserAccount = async (): Promise<string> => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error("User is not authenticated.");
  }

  try {
    // Get the user's Firebase ID token
    const idToken = await user.getIdToken();

    // Send a POST request to the backend using axios
    const response = await axios.post(
      "https://australia-southeast1-discover-do.cloudfunctions.net/deleteUserAccount",
      {}, // Empty body
      {
        headers: {
          Authorization: `Bearer ${idToken}`, // Pass the token securely
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to delete account. Try again later.");
    }

    await auth.signOut(); // Sign out the user after deletion
    return "Account deleted successfully.";
  } catch (error: unknown) {
    console.error("Error deleting account:", error);

    if (axios.isAxiosError(error)) {
      throw new Error(
        error.response?.data?.message || "Error occurred while deleting your account."
      );
    }

    throw new Error("An unknown error occurred while deleting your account.");
  }
};