import React, { useState } from 'react';
import styles from './DeleteAccount.module.css'; // Import CSS module

interface DeleteAccountProps {
  deleteUserAccount: () => Promise<string>; // Function to delete the account
}

const DeleteAccount: React.FC<DeleteAccountProps> = ({ deleteUserAccount }) => {
  const [isDeleteAccountDialogVisible, setIsDeleteAccountDialogVisible] = useState(false);
  const [isDeletingAccount, setIsDeletingAccount] = useState(false);
  const [deleteAccountStatus, setDeleteAccountStatus] = useState<string | null>(null);

  const handleDeleteAccount = async () => {
    setIsDeletingAccount(true);
    setDeleteAccountStatus(null);

    try {
      const message = await deleteUserAccount(); // Call the passed-in utility function
      setDeleteAccountStatus(message); // Display the success message
    } catch (error: unknown) {
      if (error instanceof Error) {
        setDeleteAccountStatus(error.message);
      } else {
        setDeleteAccountStatus('An unexpected error occurred.');
      }
      console.error('Error deleting account:', error);
    } finally {
      setIsDeletingAccount(false);
      setIsDeleteAccountDialogVisible(false);
    }
  };

  return (
    <div className={styles.centeredScreen}>
      <h1 className={styles.heading}>Delete Account</h1>
      <p className={styles.description}>
        Deleting your account will remove all associated data. This action cannot be undone.
      </p>

      {/* Delete button to show the confirmation dialog */}
      <button
        className={`${styles.button} ${styles.danger}`}
        onClick={() => setIsDeleteAccountDialogVisible(true)}
      >
        Delete Account
      </button>

      {/* Confirmation Dialog */}
      {isDeleteAccountDialogVisible && (
        <div className={styles.dialogOverlay}>
          <div className={styles.dialogBox}>
            <h2 className={styles.dialogTitle}>Confirm Account Deletion</h2>
            <p>Are you sure you want to delete your account? This action is irreversible.</p>
            <div className={styles.dialogActions}>
              <button
                className={`${styles.button} ${styles.confirm}`}
                onClick={handleDeleteAccount}
                disabled={isDeletingAccount}
              >
                {isDeletingAccount ? 'Deleting...' : 'Yes, Delete'}
              </button>
              <button
                className={`${styles.button} ${styles.cancel}`}
                onClick={() => setIsDeleteAccountDialogVisible(false)}
                disabled={isDeletingAccount}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Status Message */}
      {deleteAccountStatus && (
        <div
          className={`${styles.statusMessage} ${
            deleteAccountStatus.includes('Error') ? styles.error : styles.success
          }`}
        >
          {deleteAccountStatus}
        </div>
      )}
    </div>
  );
};

export default DeleteAccount;